import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Full-Stack Developer</h1>
      <div className="jobdescription col-md-12">
        Software is the glue that makes Uno a seamless experience for consumers
        and businesses.
      </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Work with our product leads and UX team to bring Uno to life in
              the digital space.
            </li>
            <li>
              Write code that powers our physical products, website, mobile app
              and other touchpoints.
            </li>
            <li>
              Help build the long term architecture of our software and
              establish product roadmap.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>
              Self-taught individual with endless curiosity to learn new tools
              and languages.
            </li>
            <li>
              Driven by a desire to create beautiful, functional products.
            </li>
            <li>
              Always looking for a better way of doing things and optimizing.
            </li>
            <li>
              A systematic thinker who can break down complicated problems.
            </li>
            <li>Able to prioritize tasks and manage workload.</li>
            <li>
              Able to distinguish between and juggle necessary quick hacks
              versus long-term solutions.
            </li>
            <li>Organized with your process, code, documentation and time.</li>
            <li>
              Independent and at the same time welcoming to feedback that makes
              the work better.
            </li>
            <li>
              Equally concerned with how good something looks on the surface and
              under the hood.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>5+ years of experience working as a full-stack developer.</li>
            <li>
              Extensive hands-on experience with Ruby/Ruby on Rails, JavaScript,
              HTML, CSS, node.js, Angular and React.
            </li>
            <li>Strong hands-on experience with AWS or equivalent. </li>
            <li>Aptitude in connecting front and back end seamlessly. </li>
            <li>
              Strong object-oriented design techniques and reusable component
              design.
            </li>
            <li>Deep understanding of working with databases.</li>
            <li>Strong debugging and diagnostic skills.</li>
            <li>Knowledge of large scale project workflows. </li>
            <li>
              Experience developing software for physical products is a plus.
            </li>
            <li>
              Experience with software design, implementation and deployment of
              embedded systems is a plus.
            </li>
            <li>Experience with Python is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
